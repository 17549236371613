export enum LoanPurposeWhat {
  HOUSE = 'House',
  HOLIDAY_HOME = 'HolidayHome',
  APARTMENT = 'Apartment',
  PLOT = 'Plot',
  OFFICE = 'Office',
  BUILDING_PLOT = 'BuildingPlot',
  BUILDING_PLOT_AND_CONSTRUCTION = 'BuildingPlotAndConstruction',
  BUILDING_PLOT_AND_HOUSE = 'BuildingPlotAndHouse',
  RECREATION_PLOT = 'RecreationPlot',
  AGRICULTURAL_PLOT = 'AgriculturalPlot',
}

export const LoanPurposeWhatDictionary = [
  {
    name_pl: 'mieszkanie',
    type: LoanPurposeWhat.APARTMENT,
  }, {
    name_pl: 'dom',
    type: LoanPurposeWhat.HOUSE,
  }, {
    name_pl: 'lokal użytkowy',
    type: LoanPurposeWhat.OFFICE,
  }, {
    name_pl: 'działka budowlana',
    type: LoanPurposeWhat.BUILDING_PLOT,
  }, {
    name_pl: 'działka budowlana i dom',
    type: LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE,
  }, {
    name_pl: 'działka budowlana z budową',
    type: LoanPurposeWhat.BUILDING_PLOT_AND_CONSTRUCTION,
  }, {
    name_pl: 'działka rekreacyjna',
    type: LoanPurposeWhat.RECREATION_PLOT,
  }, {
    name_pl: 'działka rolna',
    type: LoanPurposeWhat.AGRICULTURAL_PLOT,
  }, {
    name_pl: 'dom letniskowy',
    type: LoanPurposeWhat.HOLIDAY_HOME,
  },
];